import { EBindCondition, i18n, IProduct } from "@surelync/common";

export const getDefaultProduct = (t): IProduct => ({
    adminFeePayer: "Policyholder",
    approvals: {},
    brokerBind: EBindCondition.WithPayment,
    blocksureParty: "",
    cancellation: {
        authorisedParties: [],
        cancelTypes: ["Inception", "Custom"],
        reasons: new Array(6).fill("").map((it, index) => `cancellationDefault${index + 1}`).map(it => t(it)),
    },
    coolingOffPeriod: "P14D",
    country: null,
    created: null,
    creditPeriod: null,
    dynamicDocumentTemplates: [],
    description: null,
    hiddenAttachments: [],
    id: null,
    idRanges: {
        policies: {
            start: "000000",
            end: "999999",
            prefix: "",
            suffix: null,
        },
        claims: {
            start: "000000",
            end: "999999",
            prefix: "",
            suffix: null,
        },
    },
    insuranceType: null,
    language: null,
    lastEditedBy: "",
    lastEditedAt: "",
    lineOfBusiness: "NonLife",
    name: "",
    notary: null,
    notifications: [],
    owner: "",
    payment: {
        premiumSplits: {},
        currency: null,
        mtaFee: "1.00",
        mtaFeePayees: {
            "Platform Adjustment Fee": {
                payer: null,
                payee: "Blocksure",
                amount: "25%"
            },
            "Broker Adjustment Fee": {
                payer: null,
                payee: "Broker",
                amount: null
            }
        },
        cancellationFee: "1.00",
        cancellationFeePayees: {
            "Platform Cancellation Fee": {
                payer: null,
                payee: "Blocksure",
                amount: "25%"
            },
            "Broker Cancellation Fee": {
                payer: null,
                payee: "Broker",
                amount: null
            }
        },
        paymentFeePayees: {
            "Broker Payment Gateway Fee": {
                payer: "Broker",
                payee: "Blocksure",
                amount: "100%"
            }
        },
        additionalCharges: {
            "IPT": {
                payer: "Broker",
                payee: "Insurer",
                amount: "12%"
            }
        },
    },
    paymentGateway: "",
    partiesAndRoles: {
        Broker: "C=GB,L=London,O=TestBroker",       // FIXME
        Insurer: "C=GB,L=London,O=TestInsurer",     // FIXME
    },
    policyholderBind: EBindCondition.WithPayment,
    quoteValidity: "P7D",
    refundMode: "Disabled",
    renewal: {
        mode: "Disabled",
        quoteBeforeExpiry: null,
    },
    sections: {},
    sectionDisplayOrder: [],
    state: "Draft",
    staticAttachments: [],
    ts: null,
    validPayFrequencies: ["P1Y"],
    validPolicyTerms: ["P1Y"],
    version: null,
    countryLockedPhone: false
});

export const defaultPremiumSplit = {
    Brokerage: {
        payer: null,
        payee: null,
        amount: "30%",
        payees: {
            "Broker Platform Fee": {
                payer: null,
                payee: "Blocksure",
                amount: "25%"
            },
            "Net Brokerage": {
                payer: null,
                payee: null,
                amount: null
            }
        }
    },
    "Net Premium": {
        payer: null,
        payee: null,
        amount: null,
        payees: {
            "Insurer Platform Fee": {
                payer: null,
                payee: "Blocksure",
                amount: "0%"
            },
            "Net Net Premium": {
                payer: null,
                payee: null,
                amount: null
            }
        }
    }
};
